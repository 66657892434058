import React, { Component } from 'react'
import { GetUrlVariable } from './../../utils/functions'
import { Colors } from './../../utils/constants'
import { Link, Redirect } from 'react-router-dom'

export default class oauthSuccess extends Component {
  openEdisonMail = () => {
    var ifr = document.createElement('iframe')
    ifr.src = 'edisonmail://auth/' + encodeURIComponent(window.location.href)
    ifr.style.display = 'none'
    document.body.appendChild(ifr)
  }
  render() {
    return (
      <div className="full-height full-width">
        <div id="hero">
          <div className="small-padding section-padding">
            <div className="max-width-container">
              <h1 className="full-width centered-text hero-text">
                {'Sign In Success'}
              </h1>
              <h4 className="full-width centered-text subheading">
                {'You will be automatically redirected to Edison mail'}
              </h4>
              <p className="full-width centered-text">
                <span className="subheading">
                  {'If you are not redirected please '}
                </span>
                <span className="primary-color" onClick={this.openEdisonMail}>
                  {'click here'}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
