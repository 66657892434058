import React, { Component } from 'react'
import './header.scss'
import OnMailHeader from './onMailHeader'

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
    }
  }
  componentDidMount = () => {
    window.addEventListener('scroll', this.onScroll)
  }
  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = e => {
    let distance = document.documentElement.scrollTop
    if (distance === 0) {
      distance = document.body.scrollTop
    }
    if (distance < 80) {
      this.setState({
        active: false,
      })
    } else {
      if (!this.state.active) {
        this.setState({
          active: true,
        })
      }
    }
  }

  render() {
    let activeClass = ''
    if (window.location.pathname.indexOf('mobileHelpers') !== -1) return null
    if (this.state.active) {
      activeClass = 'active'
    }
    return (
      <div className={'header-container ' + activeClass}>
        <OnMailHeader
          active={this.state.active}
          forcedHeaderImage={this.props.forcedHeaderImage}
        />
      </div>
    )
  }
}
