import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import './modal.scss'

export default class extends Component {
  constructor() {
    super()
    this.state = {
      modalActive: false,
    }
  }
  componentDidMount = () => {
    if (this.props.modalActive) {
      this.setState({
        modalActive: true,
      })
    }
  }
  hideModal = () => {
    if (this.props.modalHidden) {
      this.props.modalHidden()
    }
    this.setState({
      hideModal: true,
    })
    setTimeout(() => {
      this.setState({
        modalActive: false,
        hideModal: false,
      })
    }, 500)
  }
  render() {
    var position = 'fixed'
    if (this.props.positionType == 'absolute') {
      position = 'absolute'
    }
    var animateOut
    if (this.state.hideModal) {
      animateOut = 'animate-out'
    }
    if (!this.state.modalActive) return null
    var closeImage = '/images/close-white.png'
    var theme = 'dark-theme'
    var additionalClasses = ''
    var xSize = '40'
    if (this.props.theme == 'light') {
      closeImage = '/images/close-black.png'
      theme = 'light-theme'
    }
    if (this.props.theme == 'circled') {
      closeImage = '/images/close-black.png'
      theme = 'light-theme'
      additionalClasses = 'shadow circle white-background'
      xSize = '30'
      if (window.innerWidth < 800) {
        xSize = '20'
      }
    }
    let frameStyle = { maxWidth: 'inherit', maxHeight: 'inherit' }
    if (this.props.fullScreen) {
      frameStyle = {
        maxWidth: 'none',
        maxHeight: 'none',
        padding: '0px',
        height: '100%',
      }
    }
    return ReactDOM.createPortal(
      <div>
        <div
          className={'modal-background' + ' ' + animateOut + ' ' + theme}
          style={{ position: position }}
        >
          {!this.props.hideCloseButton && (
            <div
              className={
                'portal-modal-close-icon padding-tiny ' + additionalClasses
              }
            >
              <img
                width={xSize}
                height={xSize}
                className={' '}
                onClick={this.hideModal}
                src={closeImage}
              />
            </div>
          )}
          <div className="modal centeret-text" style={frameStyle}>
            {this.props.content}
          </div>
        </div>
      </div>,
      document.getElementById('modal-root')
    )
  }
}
