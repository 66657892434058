import React, { Component } from 'react'
import './imageTitleText.scss'

export default class titleTextParagraph extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { image, title, description } = this.props

    return (
      <div className="">
        <img src={image} className="full-width" />
        <div className="center-for-small first-for-small small-padding">
          {title && <h3>{title}</h3>}
          {description && <h5 className="tiny-top-margin">{description}</h5>}
        </div>
      </div>
    )
  }
}
