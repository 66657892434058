import React, { Component } from 'react'
export default class BragLogos extends Component {
  render() {
    return (
      <div
        className="small-12 medium-12 large-12 row centered-text"
        style={{ justifyContent: 'center' }}
      >
        <div className="small-4 medium-4 large-3 padding-small no-bottom-padding">
          <a
            target="_blank"
            rel="noopener"
            href="https://techcrunch.com/2020/04/07/new-email-service-onmail-will-let-recipients-control-who-can-send-them-mail/"
          >
            <img
              className="full-width investor-logo"
              src="/images/logo-techcrunch.png"
              alt="Techcrunch company logo"
            />
          </a>
        </div>
        <div className="small-4 medium-4 large-3 padding-small no-bottom-padding">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.theverge.com/2020/8/31/21405753/onmail-email-service-edison-mail-launch-public-beta-paid-free"
          >
            <img
              className="full-width investor-logo"
              src="/images/logo-theverge.png"
              alt="The Verge company logo"
            />
          </a>
        </div>
        <div className="small-4 medium-4 large-3 padding-small no-bottom-padding">
          <a
            target="_blank"
            rel="noopener"
            href="https://www.protocol.com/edison-onmail-better-email-gmail"
          >
            <img
              className="full-width investor-logo"
              src="/images/logo-protocol.png"
              alt="Protocol company logo"
            />
          </a>
        </div>
      </div>
    )
  }
}
