import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { ReportFirebaseEvent } from './../../utils/analytics'
import MaterialIcon from 'material-icons-react'
import { OnmailColors, SignUpLink } from './../../utils/constants'

export default class OnmailPricing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      interval: 'yearly',
    }
  }

  componentDidMount = () => {
    ReportFirebaseEvent('onboard_marketing_pricing_viewed')
  }

  toggleInterval = () => {
    if (this.state.interval === 'monthly') {
      this.setState({
        interval: 'yearly',
      })
    } else {
      this.setState({
        interval: 'monthly',
      })
    }
  }

  choosePlan = async (plan, event) => {
    let planId
    if (plan === 'free') {
      planId = 0
    }
    if (plan === 'personal') {
      planId = this.state.interval === 'monthly' ? 1 : 4
    }
    if (plan === 'professional') {
      planId = this.state.interval === 'monthly' ? 2 : 5
    }

    await ReportFirebaseEvent(event)
    if (plan === 'free') {
      if (window.campaign) {
        window.location.assign(SignUpLink + '?campaign=' + window.campaign)
      } else {
        window.location.assign(SignUpLink)
      }
    } else {
      if (window.trial) {
        window.location.assign(
          'https://mail.onmail.com/signup?planId=' +
            planId +
            '&tr=1&campaign=' +
            window.campaign
        )
      } else if (window.campaign) {
        window.location.assign(
          'https://mail.onmail.com/signup?planId=' +
            planId +
            '&campaign=' +
            window.campaign
        )
      } else if (window.affiliateId) {
        window.location.assign(
          'https://mail.onmail.com/signup?planId=' +
            planId +
            '&affiliateId=' +
            window.affiliateId
        )
      } else {
        window.location.assign(
          'https://mail.onmail.com/signup?planId=' + planId
        )
      }
    }
  }

  render() {
    let featureData = this.props.featureData
    let pricingData = this.props.pricingData
    let priceData = pricingData[this.state.interval]
    let intervalClass = this.state.interval + '-plan-selected'
    return (
      <div>
        <div
          className="background-frame-header background-image-fill"
          style={{ backgroundImage: 'url(/images/problem-4-background.jpg)' }}
        >
          <div className="full-width full-height background-frame-overlay overlay">
            <div className="row full-height middle max-width-container">
              <div className="small-12 medium-12 large-12 padding-small centered-text">
                <h1 className="full-width white-text">Pricing</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="padding-small">
          <div className="max-width-container frame-content shadow">
            <div className="padding-small centered-text">
              <div
                id="toggle"
                className="row middle bottom-padding-small"
                style={{ flex: '0', justifyContent: 'center' }}
              >
                <h6
                  className="onmail-medium-grey"
                  style={{ 'text-transform': 'uppercase' }}
                >
                  {'Monthly'}
                </h6>
                <div className="toggle-button-wrapper">
                  <div
                    className={
                      'toggle-button background-onmail-extra-light-grey ' +
                      intervalClass
                    }
                    onClick={this.toggleInterval}
                  >
                    <img
                      className="circle background-onmail-primary shadow"
                      style={{
                        width: '22px',
                        height: '22px',
                        marginTop: '-1px',
                      }}
                      src="/images/square.png"
                      alt=" "
                    />
                  </div>
                </div>
                <h6
                  className="onmail-medium-grey"
                  style={{ 'text-transform': 'uppercase' }}
                >
                  {'Yearly'}
                </h6>
              </div>
            </div>

            <div className="row" style={{ alignItems: 'stretch' }}>
              <div className="small-12 medium-4 large-4 padding-tiny">
                <div className="card full-height card-padding">
                  <div className="row full-height">
                    <div className="small-12">
                      <h5 className="padding-bottom-tiny">Free</h5>
                      <div
                        className="row"
                        style={{ alignItems: 'flex-end', bottomMargin: '10px' }}
                      >
                        <h4 className="bold-text padding-bottom-tiny">
                          {priceData.free.price}
                        </h4>
                        <p
                          className="bold-text onmail-medium-grey margin-bottom-tiny"
                          style={{ marginLeft: '15px' }}
                        >
                          {this.state.interval === 'monthly' ? ' ' : ' '}
                        </p>
                      </div>
                      <div
                        className="row margin-bottom-tiny"
                        style={{ flex: '0' }}
                      >
                        <h6
                          className="bold-text onmail-primary background-onmail-extra-light-grey"
                          style={{ padding: '8px 16px', borderRadius: '5px' }}
                        >
                          {priceData.free.description}
                        </h6>
                      </div>
                      <div id="pricing-feature-list">
                        {featureData &&
                          featureData.free.map(data => {
                            return (
                              <div className="row margin-bottom-tiny">
                                <MaterialIcon
                                  className="material-icons small-icon"
                                  icon="check"
                                  color={OnmailColors.onmailPrimaryColor}
                                />
                                <p
                                  className="subheading bold-text"
                                  style={{ flex: '1' }}
                                >
                                  {data}
                                </p>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                    <div
                      className="small-12 mobile-top-margin"
                      style={{ alignSelf: 'flex-end' }}
                    >
                      <div
                        className="padding-top-tiny"
                        onClick={() =>
                          this.choosePlan(
                            'free',
                            'onboard_marketing_personal_clicked'
                          )
                        }
                      >
                        <div className="button-onmail full-width">
                          <div
                            className="row middle centered-text"
                            style={{ justifyContent: 'center' }}
                          >
                            <span>{'Continue'}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="small-12 medium-4 large-4 padding-tiny">
                <div className="card full-height card-padding">
                  <div className="row full-height">
                    <div className="small-12">
                      <h5 className="padding-bottom-tiny">Personal</h5>
                      <div
                        className="row"
                        style={{ alignItems: 'flex-end', bottomMargin: '10px' }}
                      >
                        <h4 className="bold-text padding-bottom-tiny">
                          {priceData.personal.price}
                        </h4>
                        <p
                          className="bold-text onmail-medium-grey margin-bottom-tiny"
                          style={{ marginLeft: '15px' }}
                        >
                          {this.state.interval === 'monthly'
                            ? '/ Month '
                            : '/ Year '}
                        </p>
                      </div>
                      <div
                        className="row margin-bottom-tiny"
                        style={{ flex: '0' }}
                      >
                        <h6
                          className="bold-text onmail-primary background-onmail-extra-light-grey"
                          style={{ padding: '8px 16px', borderRadius: '5px' }}
                        >
                          {priceData.personal.description}
                        </h6>
                      </div>
                      <div id="pricing-feature-list">
                        {featureData &&
                          featureData.personal.map(data => {
                            return (
                              <div className="row margin-bottom-tiny">
                                <MaterialIcon
                                  className="material-icons small-icon"
                                  icon="check"
                                  color={OnmailColors.onmailPrimaryColor}
                                />
                                <p
                                  className="subheading bold-text"
                                  style={{ flex: '1' }}
                                >
                                  {data}
                                </p>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                    <div
                      className="small-12 mobile-top-margin"
                      style={{ alignSelf: 'flex-end' }}
                    >
                      <div
                        className="padding-top-tiny"
                        onClick={() =>
                          this.choosePlan(
                            'personal',
                            'onboard_marketing_freelance_clicked'
                          )
                        }
                      >
                        <div className="button-onmail full-width">
                          <div
                            className="row middle centered-text"
                            style={{ justifyContent: 'center' }}
                          >
                            <span>
                              {window.trial ? 'Free for 30 days' : 'Continue'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="small-12 medium-4 large-4 padding-tiny">
                <div className="card full-height card-padding">
                  <div className="row full-height">
                    <div className="small-12">
                      <h5 className="padding-bottom-tiny">Professional</h5>
                      <div
                        className="row"
                        style={{ alignItems: 'flex-end', bottomMargin: '10px' }}
                      >
                        <h4 className="bold-text padding-bottom-tiny">
                          {priceData.pro.price}
                        </h4>
                        <p
                          className="bold-text onmail-medium-grey margin-bottom-tiny"
                          style={{ marginLeft: '15px' }}
                        >
                          {this.state.interval === 'monthly'
                            ? '/ Month / User'
                            : '/ Year / User'}
                        </p>
                      </div>
                      <div
                        className="row margin-bottom-tiny"
                        style={{ flex: '0' }}
                      >
                        <h6
                          className="bold-text onmail-primary background-onmail-extra-light-grey"
                          style={{ padding: '8px 16px', borderRadius: '5px' }}
                        >
                          {priceData.pro.description}
                        </h6>
                      </div>
                      <div id="pricing-feature-list">
                        {featureData &&
                          featureData.pro.map(data => {
                            return (
                              <div className="row margin-bottom-tiny">
                                <MaterialIcon
                                  className="material-icons small-icon"
                                  icon="check"
                                  color={OnmailColors.onmailPrimaryColor}
                                />
                                <p
                                  className="subheading bold-text"
                                  style={{ flex: '1' }}
                                >
                                  {data}
                                </p>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                    <div
                      className="small-12 mobile-top-margin"
                      style={{ alignSelf: 'flex-end' }}
                    >
                      <div
                        className="padding-top-tiny"
                        onClick={() =>
                          this.choosePlan(
                            'professional',
                            'onboard_marketing_professional_clicked'
                          )
                        }
                      >
                        <div className="button-onmail full-width">
                          <div
                            className="row middle centered-text"
                            style={{ justifyContent: 'center' }}
                          >
                            <span>
                              {window.trial ? 'Free for 30 days' : 'Continue'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
