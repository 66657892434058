import React, { Component } from 'react'
import { Colors, OnmailColors } from './../../../utils/constants'
import { Link } from 'react-router-dom'
import MaterialIcon from 'material-icons-react'
import Collapsible from 'react-collapsible'

export default class Faq extends Component {
  render() {
    return (
      <div className="onmail-faq">
        <div className="section-padding">
          <div
            className="row max-width-container"
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <div className="small-12 medium-12 large-12">
              <h3 className="full-width centered-text margin-bottom-small">
                {'Have Questions? Get Answers'}
              </h3>
              <Collapsible
                trigger={
                  <div
                    className="row middle trigger-padding"
                    style={{
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="small-11">
                      <h5 className="bold-text">
                        {
                          'What makes OnMail different from other email services?'
                        }
                      </h5>
                    </div>
                    <div className="small-1 right-text">
                      <MaterialIcon
                        className="material-icons"
                        icon="add"
                        color={Colors.white}
                      />
                    </div>
                  </div>
                }
              >
                <div className="answer-box">
                  <div className="padding-small">
                    <p className="padding-bottom-tiny">
                      {
                        'OnMail has been engineered to solve the core areas of email that are broken today. Control has been taken away from you, ad targeting has grown into an invasive norm, most email addresses that actually represent your digital identity are unavailable, and simplicity has given way to overly complicated actions in the inbox.'
                      }
                    </p>
                    <p>
                      {
                        'OnMail offers a first-of-its-kind Permission-Based Inbox, along with cool new abilities like superior search based on natural language recognition and a unique import account system that modernizes your existing email accounts in OnMail. You can send large attachments, save time with AI-based suggestions to unsubscribe, follow up on email, and perform automated inbox clean up. OnMail’s premium service plans even uniquely allow you to acquire and integrate your own custom domain into a personalized email address.'
                      }
                    </p>
                  </div>
                </div>
              </Collapsible>
              <Collapsible
                trigger={
                  <div
                    className="row middle trigger-padding"
                    style={{
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="small-11">
                      <h5 className="bold-text">
                        {'What does OnMail being "research-backed" mean?'}
                      </h5>
                    </div>
                    <div className="small-1 right-text">
                      <MaterialIcon
                        className="material-icons"
                        icon="add"
                        color={Colors.white}
                      />
                    </div>
                  </div>
                }
              >
                <div className="answer-box">
                  <div className="padding-small">
                    <p className="padding-bottom-tiny">
                      {
                        'To create a viable email service while also rejecting an ad-based business model, OnMail’s creators at Edison provide e-commerce research to businesses in the form of '
                      }
                      <a
                        href="http://trends.edison.tech/"
                        target="_blank"
                        rel="noopener"
                      >
                        {'Edison Trends'}
                      </a>
                      {
                        '. Edison creates insights about online purchase patterns from aggregated and anonymized transaction data extracted with permission from consumers using the Edison Mail app, or those who opt-in with OnMail.'
                      }
                    </p>
                    <p>
                      {
                        'For example, Edison Trends revealed the online sales impact that Nike’s iconic Colin Kaepernick ad created and often reports on which food delivery services that consumers buy from the most. See more examples of '
                      }
                      <a
                        href="http://trends.edison.tech/research"
                        target="_blank"
                        rel="noopener"
                      >
                        {'Edison Trends research'}
                      </a>
                      {
                        '. OnMail users can opt-out of sharing and delete data for Edison Trends anytime in settings.'
                      }
                    </p>
                  </div>
                </div>
              </Collapsible>
              <Collapsible
                trigger={
                  <div
                    className="row middle trigger-padding"
                    style={{
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="small-11">
                      <h5 className="bold-text">
                        {'How is OnMail different from Edison Mail?'}
                      </h5>
                    </div>
                    <div className="small-1 right-text">
                      <MaterialIcon
                        className="material-icons"
                        icon="add"
                        color={Colors.white}
                      />
                    </div>
                  </div>
                }
              >
                <div className="answer-box">
                  <div className="padding-small">
                    <p className="padding-bottom-tiny">
                      {
                        'OnMail is an email service created by Edison. With OnMail you can create a new email address @OnMail.com or a custom domain of your choice, and access unique, modern email features like Accept Sender, send large attachments, split inboxes, receive suggestions of email to unsubscribe and follow up nudges, and more.'
                      }
                    </p>
                    <p>
                      <a
                        href="http://mail.edison.tech"
                        target="_blank"
                        rel="noopener"
                      >
                        {'Edison Mail'}
                      </a>
                      {
                        ' is the #1 independent email app created by Edison to manage all your accounts from different email providers like Gmail, Outlook, iCloud and others in one place. OnMail is compatible with Edison Mail.'
                      }
                    </p>
                  </div>
                </div>
              </Collapsible>
              <Collapsible
                trigger={
                  <div
                    className="row middle trigger-padding"
                    style={{
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                    }}
                  >
                    <div className="small-11">
                      <h5 className="bold-text">
                        {'How can I access OnMail on my mobile device?'}
                      </h5>
                    </div>
                    <div className="small-1 right-text">
                      <MaterialIcon
                        className="material-icons"
                        icon="add"
                        color={Colors.white}
                      />
                    </div>
                  </div>
                }
              >
                <div className="answer-box">
                  <div className="padding-small">
                    <p className="padding-bottom-tiny">
                      {
                        'OnMail is currently available for iOS on the App Store. A new OnMail app for Android is coming very soon. Apps for Mac and Windows will also be developed.'
                      }
                    </p>
                    <p>
                      {
                        'OnMail’s email service can be used with any internet browser, and is compatible for use with the Edison Mail app for iOS, Android and Mac.'
                      }
                    </p>
                  </div>
                </div>
              </Collapsible>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
