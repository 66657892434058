import React, { Component } from 'react'
import HeroTitleText from './../../components/heroTitleText'
import TitleTextParagraph from './../../components/titleTextParagraph'
import TextSeparator from './../../components/textSeparator'
import { Helmet } from 'react-helmet'

export default class PriceMatching extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Price Alert | Edison Mail | Edison Software</title>
          <meta
            name="description"
            content="Privacy and security come first at Edison Software"
          />
        </Helmet>
        <div className="max-width-container">
          <div className="full-width extra-large-top-margin centered-text">
            <img
              id="type-image"
              className="full-width"
              style={{ maxWidth: '350px' }}
              src="/images/empty-price-drop.png"
            />
          </div>
          <TextSeparator
            subtitle="Shop with Confidence"
            title="Price Alert"
            description="Edison Mail’s Price Alert helps you shop with confidence that you’re not missing out on a chance to get some cash back. You don’t have to sign up for an extra service - just activate Price Alert for the mail accounts you want the app to monitor for price changes, then do your normal shopping. Edison Mail will take care of the rest while the receipts in your inbox help save you money in the event that a price drops."
          />
          <div className="small-padding large-bottom-margin">
            <div className="medium-top-margin">
              <TitleTextParagraph
                title="How does it work?"
                description="After you activate Price Alert, Edison’s proprietary assistant technology monitors your purchases (via your mailbox receipts) and routinely checks the online price of the item and the store location from where it was purchased. If the price drops within the company’s price matching window we will notify you and help prefill and pre-address the email to send to get your refund."
              />
            </div>
            <div className="medium-top-margin">
              <TitleTextParagraph
                title="What retailers do you support?"
                description="We cover over 30 of the largest retailers in the USA including, but not limited to, Best Buy, Walmart, Macys, Nike and more."
              />
            </div>
            <div className="medium-top-margin">
              <TitleTextParagraph
                title="How will I recieve refunds?"
                description="The price alert feature will monitor the price of the product you purchased recently and send you a notification if it drops in price. An email with the details needed will be prefilled for you to customize but the user will need to be the one to press send in order to get the refund."
              />
            </div>
            <div className="medium-top-margin">
              <TitleTextParagraph
                title="Is Price Alert available outside the US?"
                description="The price alert functionality is available in the USA only. International price alerts are something we will be considering, but do not have on our roadmap at this time."
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
