import React, { Component } from 'react'
import { Colors } from './../../utils/constants'
import './onmailHowToStep.scss'
import MaterialIcon from 'material-icons-react'
import { withController, Parallax } from 'react-scroll-parallax'
import ResponsiveImage from './../responsiveImage'

class OnmailHowToStep extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    let subtitle = this.props.subtitle
    let title = this.props.title
    let description = this.props.description
    let video = this.props.video
    let image = this.props.image

    return (
      <div className="onmail-how-to-step">
        <div className="row full-width">
          <div className="small-12 medium-5 large-5 padding-tiny">
            <div className="row full-width full-height middle">
              {video && (
                <video className="full-width" autoPlay muted loop playsInline>
                  <source src={video} type="video/mp4" />
                </video>
              )}
              {image && (
                <ResponsiveImage
                  className="full-width"
                  onLoad={this.onImageLoad}
                  src={image}
                />
              )}
            </div>
          </div>
          <div className="hide-for-small medium-1 large-1">
            <div className="row full-height">
              <div class="dashed-line">
                <div className="middle-icon-wrapper">
                  <div className="middle-icon-container">
                    <div class="dot centered-text" style={{ flexBasis: '0' }}>
                      <MaterialIcon
                        className="material-icons tiny-icon"
                        icon="trip_origin"
                        color={Colors.primaryColor}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={'small-12 medium-6 large-6 padding-tiny row'}
            style={{
              alignItems: 'center',
              alignContent: 'center',
              position: 'relative',
            }}
          >
            <div className="full-width">
              <h4 className="bold-text onmail-medium-grey margin-bottom-tiny">
                {subtitle}
              </h4>
              <h3 className="margin-bottom-tiny">{title}</h3>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withController(OnmailHowToStep)
