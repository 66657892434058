import React, { Component } from 'react'
import './titleTextParagraph.scss'

export default class titleTextParagraph extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { title, description, htmlDescription, descriptionColor } = this.props
    let alignment =
      this.props.alignment == 'centered-text' ? 'centered-text' : 'left-text'

    return (
      <div className={alignment}>
        {title && <h3>{title}</h3>}
        {description && (
          <p
            className="subheading tiny-padding no-left-padding no-right-padding"
            style={descriptionColor ? { color: descriptionColor } : {}}
          >
            {description}
          </p>
        )}
        {htmlDescription && htmlDescription}
      </div>
    )
  }
}
