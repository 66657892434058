import React, { Component } from 'react'
import './heroTitleText.scss'
import { Link } from 'react-router-dom'
import TrackedLink from './../../components/trackedLink'

export default class HeroTitleText extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    let title1 = this.props.title1
    let highlight1 = this.props.highlight1
    let title2 = this.props.title2
    let highlight2 = this.props.highlight2
    let title3 = this.props.title3
    let description = this.props.description
    let buttonTitle = this.props.buttonTitle
    let buttonLink = this.props.buttonLink
    let buttonTitle2 = this.props.buttonTitle2
    let buttonLink2 = this.props.buttonLink2
    let appStoreLink = this.props.appStoreLink
    let appStoreButtonSrc = this.props.appStoreButtonSrc
    let alignment =
      this.props.alignment == 'centered-text' ? 'centered-text' : 'left-text'
    let margin = this.props.margin
    let postDescriptionLink = this.props.postDescriptionLink
    let postDescriptionLinkText = this.props.postDescriptionLinkText

    if (this.props.alignment == 'centered-text') {
      margin = 'center-position'
    }
    return (
      <div
        className="hero-text-wrapper row animated"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <div
          className={
            'full-width small-padding max-width-container ' + alignment
          }
          style={{ position: 'relative', zIndex: '2' }}
        >
          <div className={'hero-text-max-width ' + margin + ' '}>
            <h1 className="hero-text">
              <span>{title1}</span>
              <span className="primary-color">{highlight1}</span>{' '}
              <span>{title2}</span>{' '}
              <span className="primary-color">{highlight2}</span>{' '}
              <span>{title3}</span>
            </h1>
            {description && (
              <p class="hero-description full-width tiny-top-margin medium-grey-color">
                {description}{' '}
                {postDescriptionLink && (
                  <a style={{ cursor: 'pointer' }} href={postDescriptionLink}>
                    {postDescriptionLinkText}.
                  </a>
                )}
              </p>
            )}
            {buttonLink && (
              <a
                className="button hero-button primary-background tiny-top-margin"
                target="_blank"
                style={{ color: '#fff' }}
                href={buttonLink}
              >
                {buttonTitle}
              </a>
            )}
            {buttonLink2 && (
              <a
                className="button hero-button primary-background tiny-top-margin"
                target="_blank"
                style={{ color: '#fff', marginLeft: '20px' }}
                href={buttonLink2}
              >
                {buttonTitle2}
              </a>
            )}
            <div className="tiny-top-margin">
              <TrackedLink link={appStoreLink}>
                <img style={{ width: '200px' }} src={appStoreButtonSrc} />
              </TrackedLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
