import React, { Component } from 'react'
import './videoHero.scss'
import VideoCover from 'react-background-video-player'
export default class Hero extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ready: false,
    }
    this.childRef = React.createRef()
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }
  updateDimensions() {
    const elem = this.childRef.current
    if (elem) {
      let height = elem.clientHeight
      let width = elem.clientWidth
      this.setState({
        height: height,
        width: width,
      })
    }
  }

  render() {
    let videoBackgroundClass = this.state.ready ? 'ready' : ''
    let tintOpacity = this.props.tintOpacity ? this.props.tintOpacity : 0
    return (
      <div className={'video-hero'}>
        <div className={'video-hero-foreground'} ref={this.childRef}>
          {this.props.children}
        </div>
        <div
          className={'video-hero-tint'}
          style={{ opacity: tintOpacity }}
        ></div>
        <div className={'video-hero-background ' + videoBackgroundClass}>
          <VideoCover
            src={this.props.video}
            containerWidth={this.state.width}
            containerHeight={this.state.height}
            autoPlay={true}
            loop={true}
            muted={this.props.sound ? false : true}
            playsInline={true}
          />
        </div>
      </div>
    )
  }
}
