import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

export default class textOverImageBlock extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Fade bottom distance="4%" duration={1500}>
        {this.props.children}
      </Fade>
    )
  }
}
