import React, { Component } from 'react'
import { Colors } from './../../utils/constants'
import './onmailLeftRightLayout.scss'
import { withController, Parallax } from 'react-scroll-parallax'
import ResponsiveImage from './../responsiveImage'
import MaterialIcon from 'material-icons-react'

class OnmailLeftRightLayout extends Component {
  constructor(props) {
    super(props)
  }
  onImageLoad = () => {
    this.props.parallaxController.update()
  }
  render() {
    let title = this.props.title
    let description = this.props.description
    let image = this.props.image
    let video = this.props.video
    let backgroundImage = this.props.backgroundImage
    let imageOrder = ''

    if (this.props.layout == 'right') {
      imageOrder = '-1'
    }

    return (
      <div className="onmail-left-right-layout">
        <div className="row full-screen-container">
          <div
            className="row full-width max-width-container"
            style={{
              alignItems: 'center',
              alignContent: 'center',
              position: 'relative',
            }}
          >
            <div
              className={
                'small-12 medium-5 large-5 padding-small first-for-small center-for-small '
              }
            >
              <h2>{title}</h2>
              <h4 className="padding-top-tiny regular-text">{description}</h4>
            </div>
            <div className="small-12 medium-7 large-7">
              <div
                className="margin-small"
                style={{
                  backgroundImage: 'url(' + backgroundImage + ')',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  marginBottom: '-1px',
                  overflow: 'hidden',
                }}
              >
                {image && (
                  <ResponsiveImage
                    className="full-width"
                    onLoad={this.onImageLoad}
                    src={image}
                  />
                )}
                {video && (
                  <video className="full-width" autoPlay muted loop playsInline>
                    <source src={video} type="video/mp4" />
                  </video>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withController(OnmailLeftRightLayout)
