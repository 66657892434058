import React, { Component } from 'react'
import {
  OnmailColors,
  LatestDownloadableOnmailIosLink,
} from './../../utils/constants'
import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import TrackedLink from './../../components/trackedLink'
import MaterialIcon from 'material-icons-react'
import { Helmet } from 'react-helmet'
import ScrollFadeIn from './../../components/scrollFadeIn'
import OnmailFramedFullWidthImageText from './../../components/onmailFramedFullWidthImageText'
import OnMailPopUpFrame from './../../components/onMailPopUpFrame'
import PortalModal from './../../components/portalModal'
import VideoHero from './../../components/videoHero'
import Footer from './../../components/footer'
import './vision.scss'

export default class OnmailVision extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  showModal = type => {
    this.hideModal()
    this.setState({
      [type]: true,
    })
  }
  hideModal = () => {
    this.setState({
      problem1Expanded: false,
      problem2Expanded: false,
      problem3Expanded: false,
      problem4Expanded: false,
      problem5Expanded: false,
      problem6Expanded: false,
    })
  }
  render() {
    return (
      <div className="full-width onmail-vision-page">
        <Helmet>
          <title>
            Vision — How We're Solving Email's Core Problems | By Edison
            Software
          </title>
          <meta
            name="description"
            content="Finally, an independent email service that gives you full control. Understand the ways we are improving the next generation of email. "
          />
          <meta
            name="google-site-verification"
            content="yZ638yt0GZu1rqnAT2klj_g5IAsyi_33FQ_4MuBnptI"
          />
          <meta
            name="p:domain_verify"
            content="0703ddff50b36ba64a2edc3507c449ad"
          />
          <meta
            name="B-verify"
            content="3e7503ca0cb04368d4a783b926f0d84b95e74293"
          />
          <meta
            name="google-site-verification"
            content="JE63RlPVGqntnNKUZH5NpH3JO5SK2wPadGPkJ0FaIrY"
          />
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-MC8GN8X"></script>
          <meta name="theme-color" content="#2C2C2C" />
          <link rel="icon" type="image/png" href="/images/onmail-favicon.png" />
          /* OpenGraph tags */
          <meta
            property="og:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            property="og:description"
            content="Experience email built for today."
          />
          <meta
            property="og:image"
            content="/images/onmail-social-share-img.png"
          />
          <meta property="og:url" content="https://www.onmail.com" />
          /* Twitter Card tags */
          <meta
            name="twitter:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            name="twitter:description"
            content="Experience email built for today."
          />
          <meta
            name="twitter:image"
            content="/images/onmail-social-share-img-vision.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#2C2C2C" />
        </Helmet>

        <div className="">
          <VideoHero
            video="https://player.vimeo.com/external/534587324.hd.mp4?s=a583c23c0b144ba085e00357f226430472f4d6bc&profile_id=175"
            tintOpacity=".01"
          >
            <div
              className="overlay hero-video-overlay middle"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div className="max-width-container">
                <div
                  className="row full-height middle"
                  style={{ justifyContent: 'center' }}
                >
                  <div className="small-12 medium-10 large-7 centered-text padding-small">
                    <h1>{'Our Vision'}</h1>
                    <h4>
                      {
                        'We believe in creating a faster, simpler, and smarter email service. Built around the core values of speed, safety, control, and flexibility. We set out to solve the biggest problems in email to help you spend less time in your'
                      }
                      &nbsp;{'inbox.'}
                    </h4>
                  </div>
                </div>
              </div>

              <div
                className="padding-small centered-text"
                style={{
                  position: 'absolute',
                  bottom: '20px',
                  maxWidth: '310px',
                }}
              >
                <AnchorLink className="hero" href={'#problems'}>
                  <p className="bold-text white-text">
                    {'learn about the problems we’re'}&nbsp;{'solving'}
                  </p>
                  <div className="padding-small">
                    <MaterialIcon
                      className="material-icons"
                      icon="arrow_downward"
                      color={OnmailColors.onmailWhite}
                    />
                  </div>
                </AnchorLink>
              </div>
            </div>
          </VideoHero>
        </div>

        <div id="problems" className="">
          <OnmailFramedFullWidthImageText
            title="Safe"
            description="Anti-tracking technology blocks intrusive ad targeters from spying on your emails. Powerful safeguards in your inbox deliver modern anti-spam and anti-phishing protection."
            linkTitle="Read more"
            link="localhost:3000"
            onClick={() => this.showModal('problem1Expanded')}
            backgroundImage="/images/problem-1-background.jpg"
          />
          {this.state.problem1Expanded && (
            <PortalModal
              modalHidden={this.hideModal}
              modalActive={this.state.problem1Expanded}
              theme="circled"
              fullScreen
              content={
                <OnMailPopUpFrame
                  title={'Safe'}
                  description={
                    'Somewhere along the way we got lost. People no longer feel safe in their inbox. This must change.'
                  }
                  image={'/images/problem-1-background.jpg'}
                  maxwidth="small-max-width-container"
                >
                  <div>
                    <div className="text-max-width-container">
                      <p className="margin-bottom-small">
                        {
                          'Safety is a fundamental human need for all of us. In the real world, whether you’re walking to the store or handing over your credit card details to buy something, there is nothing more important than your feeling of personal safety.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'This is just as true in our digital life. Somewhere along the way email, the place we spend most of our time when we are online, became a place that no longer feels safe. The invention of read receipts and wide scale phishing attempts exploited dangerous holes in the infrastructure of large corporations that we regularly engage. Spammers found loopholes in legislation to trick people into clicking fake unsubscribe links.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Other big tech ad-based email services rely on your email address as a unique identifier across their free products to trace you and your internet behaviors to target you even better for advertisements.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'With so many pervasive email threats, you eventually accepted them as becoming a new “norm” of your email experience. We have all sadly learned to accept that the inbox is no longer a safe place. It is a place in which you hesitate before opening a message, a place where you second guess every link you click, and finally, a place you can no longer actually trust. Every email from a sender is approached with caution.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'These problems are tough to solve. They require lengthy research and development cycles to bring safety back to your inbox.'
                        }
                      </p>
                    </div>
                    <div className="full-width padding-bottom-small padding-top-small">
                      <img
                        className="full-width"
                        src="/images/block-read-receipts-banner.jpg"
                        alt=""
                      />
                    </div>
                    <div className="text-max-width-container">
                      <div className="quote margin-top-medium margin-bottom-medium">
                        <h4>
                          {
                            'Every time an email sender sees that you opened their message or that you clicked to unsubscribe from their emails, you are actually just triggering even more spam. We all live in constant fear that an email we receive is fraudulent in some way. It’s clear that over the years, safety stopped being a priority in email. This must change.'
                          }
                        </h4>
                      </div>
                      <p className="bold-text">
                        {'Don’t Use Email Without Blocking Read Receipts'}
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'In a world where technology is built for tracking your every move online, the business of including mini spy pixels in your every email, also known as hidden read receipts, has become the new standard. Although useful to the sender, it is a massive invasion of your privacy as the recipient. Akin to having a camera mounted in your home, wide open for the world to see. Not only do we question the legality of these practices, we took a committed approach to never let them near your inbox, not in our service. By pre-processing emails as they come in we are able to remove all tracking pixels before they enter your inbox. OnMail does not treat your email account like a traceable web cookie to track and target you with ads. Read receipts are always blocked in every email, without disabling images or interrupting your experience. We have created an email service that behaves as your own personal guard. A first line of defense is embedded in your inbox. We are invested in continuing to research and develop ways to stop future email tracking technologies before they start.'
                        }
                      </p>
                      <p className="bold-text">
                        {'Unsubscribe That Just Works'}
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'We’ve all been there. You’ve been added to a new mailing list you didn’t sign up to receive. It happened because another mailing list you were on was sold. How do you stop this cycle? Do you click the unsubscribe link at the bottom of the inbox? Or, will that link verify your email exists in a sales and marketing “spray and pray” method of outreach that serves as a data collection strategy for spammers. The truth is… you don’t know. Nobody can know for sure. You can click the unsubscribe button and it will likely do nothing. You will continue to get messages from the sender. You can click the button and it will likely sign you up for even more questionable email lists flooding your inbox until the end of time. It’s like disarming a live, underground mine. The truth is, you just don’t want to receive emails from this sender anymore. This is where we can help. We do the heavy lifting for you the simple way. The safe way. We add the sender to an internal block list which will remove messages from that sender from your inbox. This can all be done without ever leaving your inbox or revealing any information to potential spammers. If you don’t want to receive emails from this sender you can trust us to help.'
                        }
                      </p>
                      <p className="bold-text">{'Understanding a Sender'}</p>
                      <p className="margin-bottom-small">
                        {
                          'One of the most innovative parts of our service is the way we approve a sender to let them into your inbox. We didn’t stop there. What could we present to you, so that you can make a better informed decision? We automatically run a security audit on your email message headers to understand if they may be prone to phishing attacks. You are informed of potential phishing efforts as they enter your inbox.​ We also let you know when senders are using pixel tracking technology. This information can be very useful when determining if you would like to interact with that sender again in the future. We allow you to make informed decisions for your safety.'
                        }
                      </p>
                      <p className="bold-text">{'Our Promise'}</p>
                      <p className="margin-bottom-small">
                        {
                          'OnMail is safeguarded by multiple layers of security, including user specific encryption keys built with powerful algorithms. Your data is encrypted at rest and in-transit. You can choose whether to Opt-In to share de-identified ecommerce data with Edison for its aggregated'
                        }{' '}
                        <Link to="/privacy-commitments">
                          {'research-backed business model.'}
                        </Link>
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Our mission to restore safety in the inbox is just beginning. Our promise to you is to continue to push as hard as we can to get there. We firmly believe that the fundamental feeling of safety is crucial in the future of email. We are excited to continue to be the pioneers in this space.'
                        }
                      </p>
                    </div>
                  </div>
                </OnMailPopUpFrame>
              }
            />
          )}
        </div>
        <div className="">
          <OnmailFramedFullWidthImageText
            title="Control"
            description="We empower you to end unwanted email. Read less, search less, delete less."
            linkTitle="Read more"
            link="localhost:3000"
            onClick={() => this.showModal('problem2Expanded')}
            backgroundImage="/images/problem-2-background.jpg"
          />
          {this.state.problem2Expanded && (
            <PortalModal
              modalHidden={this.hideModal}
              modalActive={this.state.problem2Expanded}
              theme="circled"
              fullScreen
              content={
                <OnMailPopUpFrame
                  title={'Control'}
                  description={
                    'When you don’t have control over what enters your inbox, your inbox is no longer yours.'
                  }
                  image={'/images/problem-2-background.jpg'}
                  maxwidth="small-max-width-container"
                >
                  <div>
                    <div className="text-max-width-container">
                      <p className="margin-bottom-small">
                        {
                          'When email was first created, instant and open communication felt like magic. But it was also a time before inboxes overflowed daily from spam, marketing messages, and scammers. Since then, email has spiraled out of control, and deleting unwanted emails has become the number one task most people complete in their inboxes daily. Email has scaled beyond its original design and you are suffering from a lack of control over your own inboxes. Anyone can fill up your inbox with email and do it freely, just because they have your email address.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'In today’s world, email senders have all the power while you, the email recipient, have almost none. This imbalance is so baked into the fabric of email today that it takes some time to realize. After all, all someone needs to get directly in front of your eyes, take up your attention, and eat up your time is your email address. This is akin to strangers walking into your home and hanging out on your living room couch just because they know your home address.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'This dynamic is also especially archaic when you realize other digital communication platforms like social media have already solved this issue. Instagram, Facebook, Twitter, and other similar online profiles allow you to decide who gets access into your space. They have a myriad of privacy filters, the ability to make your profile private, and simple ways to block unwanted individuals.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'A communication tool lacking this basic level of user control is where email stands alone.'
                        }
                      </p>
                    </div>
                    <div className="full-width padding-bottom-small padding-top-small">
                      <img
                        className="full-width"
                        src="/images/accept-sender-banner.jpg"
                        alt=""
                      />
                    </div>
                    <div className="text-max-width-container">
                      <div className="quote margin-top-medium margin-bottom-medium">
                        <h4>
                          {
                            'In today’s world, email senders have all the power while you, the email recipient, have almost none.'
                          }
                        </h4>
                      </div>
                      <p className="bold-text">
                        {'You Choose Who Enters Your Inbox'}
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'We have revolutionized the battle against email spam with a brand new ability called Accept Sender. Accept Sender offers Permission-Control our team invented that empowers you to accept or block new email senders before they enter your inbox, thus preventing spam access by unwanted senders before it starts.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Never worry about giving your email addresses away again, because now you can control what happens when someone has the address. At the top of your OnMail inbox, separate from all the approved email messages, you can one tap accept or deny senders access to their inboxes. Once denied, all messages from that email sender will be sent directly to the Trash, never again bothering you the recipient. To undo this action, all you have to do is access the Blocked list from the Settings Menu and accept that sender.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'With a single click, you can save precious hours in your life and ensure that the only individuals in your inbox are the ones you want to be there. You’re no longer left at the mercy of whoever has your email address. Instead of managing an overloaded inbox, you’re able to stop the inbox from ever getting full to begin with.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {'Take back the power back to control your mailbox.'}
                      </p>
                    </div>
                  </div>
                </OnMailPopUpFrame>
              }
            />
          )}
        </div>
        <div className="">
          <OnmailFramedFullWidthImageText
            title="Yours"
            description="Make email your own. Get the best address to represent your online identity, permanently."
            linkTitle="Read more"
            link="localhost:3000"
            onClick={() => this.showModal('problem3Expanded')}
            backgroundImage="/images/problem-3-background.jpg"
          />
          {this.state.problem3Expanded && (
            <PortalModal
              modalHidden={this.hideModal}
              modalActive={this.state.problem3Expanded}
              theme="circled"
              fullScreen
              content={
                <OnMailPopUpFrame
                  title={'Yours'}
                  description={
                    'Most people who want to create a new email address find themselves unable to get a name they actually want.'
                  }
                  image={'/images/problem-3-background.jpg'}
                  maxwidth="small-max-width-container"
                >
                  <div>
                    <div className="text-max-width-container">
                      <p className="margin-bottom-small">
                        {
                          'In today’s digital world, having the right email address plays an important role in how you will be perceived, as it’s often the first impression you’ll make online. But for most of us trying to find a fantastic, and more importantly available, email address, pickings can be slim. '
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'With the bulk of the world relying only on a few big email service providers, email addresses have evolved into a finite resource. Email has been around for decades now, and in that time nearly all good combinations of first names, last names, numbers, and underscores have been swiped up.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'How can you find a meaningful online identity that represents you when, let’s face it, all the best email addresses are gone?'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Most people who want to create a new email address find themselves unable to get a name they actually want, likely because 85% of the U.S. email market is captured by only three big tech email services.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Also consider how much has changed in the internet landscape in the last 10 years. The web was created to publish and share information. In the past, you had to rely on a website or blog to do this. No more. The growth of micro blogs, social networks, and socially-driven third-party platforms have become the second generation of publishing content online.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Today, you can rely exclusively on social media followers or channel subscribers to perpetuate your content marketing engine and communicate with your audience directly. You no longer need your own website, however, you do need your own email address.'
                        }
                      </p>
                      <p className="bold-text">
                        {'The Consumerization of Custom Domains'}
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'We re-imagined the way we look at web domains. Looking into the future of domains, a clear path is laid out for a shift toward email identity and away from a custom made web presence. This is a fundamentally different approach from how web domains have traditionally been used, and has a significant impact on the need for a more democratized method of acquiring them.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'In the past, you would have to research domain providers (e.g. GoDaddy.com) and create an account on a site that you will never login again. Then hire a developer skilled enough to complete many technical steps and manage very technical information required to set up an email address that then connects with that domain name. But… that isn’t right.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'You have to hire a developer to manage the settings needed to set up an email address? Is this truly necessary? No.'
                        }
                      </p>
                    </div>
                    <div className="full-width padding-bottom-small padding-top-small">
                      <img
                        className="full-width"
                        src="/images/custom-domain-banner.jpg"
                        alt=""
                      />
                    </div>
                    <div className="text-max-width-container">
                      <div className="quote margin-top-medium margin-bottom-medium">
                        <h4>
                          {
                            'How can you find a meaningful online identity that represents you when, let’s face it, all the best email addresses are gone?'
                          }
                        </h4>
                      </div>
                      <p className="bold-text">{'Make Email Your Own'}</p>
                      <p className="margin-bottom-small">
                        {
                          'We engineered OnMail to do all the heavy lifting for setting up an email address including buying a custom domain--taking a process that would originally take days and require technical skill and turning it into a couple of seconds for any consumer to set up effortlessly.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'The future of online communication is giving the world easy access to a personalized and permanent email address that represents you, easily and without having to deal with complicated web setup. This is just a proof point of our vision into the future email plays in the next generation of internet growth.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'We give you the power of choice: select an email address that actually represents you and customize your inbox to best fit your email needs. Create a brand new name @onmail.com or use OnMail to acquire your very own custom domain to personalize your email address for your personal or business use.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Finally, a true marriage of email and web domains to give people access to an email address they are passionate about.'
                        }
                      </p>
                    </div>
                  </div>
                </OnMailPopUpFrame>
              }
            />
          )}
        </div>
        <div className="">
          <OnmailFramedFullWidthImageText
            title="Dynamic"
            description="Work your way. Every email is different. Customize your inbox workflows as your needs change. "
            linkTitle="Read more"
            link="localhost:3000"
            onClick={() => this.showModal('problem4Expanded')}
            backgroundImage="/images/problem-4-background.jpg"
          />
          {this.state.problem4Expanded && (
            <PortalModal
              modalHidden={this.hideModal}
              modalActive={this.state.problem4Expanded}
              theme="circled"
              fullScreen
              content={
                <OnMailPopUpFrame
                  title={'Dynamic'}
                  description={
                    'Nobody emails the same way, but too many email services only offer a one size fits all approach.'
                  }
                  image={'/images/problem-4-background.jpg'}
                  maxwidth="small-max-width-container"
                >
                  <div>
                    <div className="text-max-width-container">
                      <p className="margin-bottom-small">
                        {
                          'Back in the early days, Email was used as a form of digital communication between people. Email has scaled rapidly to become much larger and evolve into more diverse forms of digital communication. ​There is no way that the future of email will be able to treat every email as the same in a view that resembles a spreadsheet when you look into a densely packed inbox.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'It’s better to embrace the changes and design for scalability.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Many email services also lack the ability to allow users to fully customize their email experience and flow. Nobody emails the same way, but too many email services only offer a one size fits all approach.'
                        }
                      </p>
                    </div>
                    <div className="full-width padding-bottom-small padding-top-small">
                      <img
                        className="full-width"
                        src="/images/preview-mode-banner.jpg"
                        alt=""
                      />
                    </div>
                    <div className="text-max-width-container">
                      <div className="quote margin-top-medium margin-bottom-medium">
                        <h4>
                          {
                            'Nobody emails the same way, but too many email services only offer a one size fits all approach.'
                          }
                        </h4>
                      </div>
                      <p className="margin-bottom-small">
                        {
                          'With OnMail, we created a browsable interface for promotional emails and a suite of actions should also be catered to support a variety of new challenges for different types of emails.​ This is allowing you to consume different types of content differently because they are of course different. ​A fully customizable email experience at your fingertips.'
                        }
                      </p>
                      <p className="bold-text">
                        {'Stay on Top of What Matters'}&mdash;
                        {'Split Your Inbox & Get Intelligent Notifications'}
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Nobody organizes their inbox the same. Now you can create several customizable inboxes you designate to keep tabs on important messages, and see what matters to you most. Whether it’s planning a trip, collaborating on a special project, never missing updates from family, or keeping up with a group of friends or peers.​ Tailor your inbox views to best suit your workflow and get through your messages faster. '
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'You can also choose from a selection of intelligent notification options and only get alerts when you want them. For example, customize your preferences to receive email notification alerts for only your Primary, Other, or Split Inbox emails. This is a great way to reduce unnecessary distractions from your inbox when you’re trying to focus on being present in a certain moment.'
                        }
                      </p>
                      <p className="bold-text">
                        {
                          'Slip Into Preview Mode to Consume News & Offers Faster'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'When looking at a full inbox, it’s all too easy to miss something important. Nobody has time to open every message and read every line of text when dozens of emails are waiting to be read. In today’s world, the volume of news and promotional emails we receive outnumbers the amount of time we have to consume them.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'That’s why we created Preview Mode, a brand new way for you to save time consuming your favorite news and promotions in a more intuitive, visual experience. In Preview Mode, all the emails you sign up to receive for news, offers from brands you love, social media updates, and more are displayed at a glance.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Preview Mode presents you with a stunning image-driven feed of emails to scroll through in seconds. Enjoy your favorite email newsletters in snackable previews. Email content is displayed in all its glory and brilliant color, without tediously tapping in and out of each individual message.'
                        }
                      </p>
                      <p className="">
                        {
                          'Get through your inbox faster than ever before with an easier way to choose which messages to give your attention or dismiss. Never waste time or scramble to catch up on every email you worry about missing. After one quick look, if there’s nothing appealing in your inbox you can simply mark all your Other emails as done with a single tap.'
                        }
                      </p>
                    </div>
                  </div>
                </OnMailPopUpFrame>
              }
            />
          )}
        </div>
        <div className="">
          <OnmailFramedFullWidthImageText
            title="Universal"
            description="Modernize all your accounts across providers to experience a more consistent and higher standard of email performance."
            linkTitle="Read more"
            link="localhost:3000"
            onClick={() => this.showModal('problem5Expanded')}
            backgroundImage="/images/problem-5-background.jpg"
          />
          {this.state.problem5Expanded && (
            <PortalModal
              modalHidden={this.hideModal}
              modalActive={this.state.problem5Expanded}
              theme="circled"
              fullScreen
              content={
                <OnMailPopUpFrame
                  title={'Universal'}
                  description={
                    'Your work email, Internet Service Provider (ISP) email, and even custom web domain email accounts are often just not practical to walk away from.'
                  }
                  image={'/images/problem-5-background.jpg'}
                  maxwidth="small-max-width-container"
                >
                  <div>
                    <div className="text-max-width-container">
                      <p className="margin-bottom-small">
                        {
                          'Changing an email address is the primary obstacle preventing most of us from cutting ties with a legacy email provider. So, most of us decide to keep our accounts even if we wish we had a better email service. Ultimately, on average every one of us ends up with anywhere from three to as many as seven email accounts we are monitoring, regularly.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'And to allow you to manage all your accounts in one place many email products today rely on workarounds like email forwarding. However, we have learned that this is simply a quick fix-- a bandaid on a much larger hole in this industry. Side effects include major performance degradations like delays in email notification and delivery speeds.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          '​Another approach to creating a universal email product involves piggybacking on the third party mail servers. This can be thought of as a way to combine all mail providers in an app in a cost effective way. But, this too comes with known limitations, as we have learned. You are then relying on the speed and performance of the mail provider itself, and in many cases, varies greatly between providers. You are also combining “concepts” which have been implemented by the mail providers as differentiation for their product.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'If one email service offers an archive and labels concept but another provider does not, your developer will need to do some trickery to make an experience that works for you. At times, this may also cause a partially muted experience as your developer tries to support a wide variety of services.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Furthermore, when piggybacking, your developer only has limited access to just a small window of your inbox. Since you cannot store your entire mailbox on your mobile device, you are then relying on your different email providers to generate their respective approaches to performing email actions from which results can also vary. For example if you wanted to search for something like “home mortgage docs” you will get different approaches at determining the best results.'
                        }
                      </p>
                    </div>
                    <div className="full-width padding-bottom-small padding-top-small">
                      <img
                        className="full-width"
                        src="/images/import-account-banner.jpg"
                        alt=""
                      />
                    </div>
                    <div className="text-max-width-container">
                      <div className="quote margin-top-medium margin-bottom-medium">
                        <h4>
                          {
                            'Changing an email address is the primary obstacle preventing most of us from cutting ties with a legacy email provider.'
                          }
                        </h4>
                      </div>
                      <p className="margin-bottom-small">
                        {
                          'To fix this issue of offering an easy to implement and drastically improved email experience that could be consistent across all email services you use, we immersed ourselves into this problem and fix it at its core. We want you to be able to bring your accounts with you but not at the expense of sacrificing the experience of OnMail. So, we built an entirely new account import system.'
                        }
                      </p>
                      <p className="bold-text">
                        {'Consolidate Your Email Services Painlessly'}
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Our proprietary import account system offers you a brand new way to modernize all of your existing email accounts. You can bring over your Gmail, Outlook, and more accounts from other providers to access unique OnMail features in an improved, consistent experience across all your email accounts. For example, faster speed and superior search across all your existing accounts and mail history, access features like accept/ block senders, filtering rules based on the email address where you received your messages and more.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Unlike other email products, OnMail was built from the ground up and avoids common industry workarounds like email forwarding or piggybacking on third party email servers. Our powerful new importing system allows you to experience the best of both worlds — keep your existing email provider and benefit from exclusive and cutting edge OnMail features including: revolutionary accept sender control, state-of-the-art spam protection, powerful AI-based follow up nudges and unsubscribe suggestions, automatic read receipt blocking, productivity increasing thread view, and much more.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Experience a higher standard of email performance across all of your email accounts with Gmail, Outlook, iCloud, Hotmail, AOL or MSN providers. We devoted years and powerful engineering expertise to develop a revamped and unmatched underlying infrastructure of your existing mailbox capable of syncing your entire account history in real-time and support every unique OnMail feature out of the box.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Your old email account will instantly be smarter in OnMail. OnMail’s Natural Language search engine will process your entire email history and index it to support the next generation of search. Features like Smart Reply and Suggested Unsubscribe will now work across your entire set of accounts based on your full inbox history of old emails brought into your inbox. There will be no more delays in notification and delivery speeds across all your accounts imported to our service.'
                        }
                      </p>
                    </div>
                  </div>
                </OnMailPopUpFrame>
              }
            />
          )}
        </div>
        <div className="">
          <OnmailFramedFullWidthImageText
            title="Clean"
            description="Email forged for exceptional simplicity. Meticulous and well-balanced design for a cleaner inbox."
            linkTitle="Read more"
            link="localhost:3000"
            onClick={() => this.showModal('problem6Expanded')}
            backgroundImage="/images/problem-6-background.jpg"
          />
          {this.state.problem6Expanded && (
            <PortalModal
              modalHidden={this.hideModal}
              modalActive={this.state.problem6Expanded}
              theme="circled"
              fullScreen
              content={
                <OnMailPopUpFrame
                  title={'Clean'}
                  description={
                    ' An email service should not be overly complex and bloated with extra features you really don’t need or use in your inbox.'
                  }
                  image={'/images/problem-6-background.jpg'}
                  maxwidth="small-max-width-container"
                >
                  <div>
                    <div className="text-max-width-container">
                      <p className="margin-bottom-small">
                        {
                          'Email usage has scaled rapidly since its early days, becoming much larger and used more diversely as a form of communication. Email today is required beyond just simple correspondence between people. To apply for a new job, you need an email address for recruiters to get in touch with you. To log into a social media, shopping, or news platform you need an email address to sign up and create an account. Every aspect of your online identity touches email in some way to either validate that you are who you say you are, or deliver a confirmation or solicitation of some kind to you.'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'Yet as critical as email is to your digital lifestyle today, traditional inboxes remain clunky, riddled with needless buttons and gizmos, and hard to navigate. Even with an empty inbox, there’s simply too much on the screen. You miss out on any breathing room and instead get bogged down by all there is in front of you.'
                        }
                      </p>
                    </div>
                    <div className="full-width padding-bottom-small padding-top-small">
                      <img
                        className="full-width"
                        src="/images/mark-as-done-banner.jpg"
                        alt=""
                      />
                    </div>
                    <div className="text-max-width-container">
                      <div className="quote margin-top-medium margin-bottom-medium">
                        <h4>
                          {
                            ' We spent a lot of time looking at the 80% of features and functionality that people use the most in email. We kept in only what you need, and nothing you don’t, to deliver a powerful and incredibly clean UI.'
                          }
                        </h4>
                      </div>
                      <p className="margin-bottom-small">
                        {
                          '​​OnMail’s approach is to embrace the changes of your modern use with email, design for scale, and treat every email categorically. No more spreadsheet style views where everything looks the same. With these new challenges, our email service interface was designed to support things like bulk actions for promotional (or newsletter) style emails and more.​'
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'We trimmed down the excess and created a UI platform that prioritizes sleek minimalism. There aren’t any needless distracting buttons or ads dancing across the screen, just everything you need and nothing you don’t. Every pixel was examined and re-examined to ensure that the entire interface is clean and well-balanced, prioritizing the end user experience above all else.​​'
                        }
                      </p>
                      <p className="bold-text">
                        {'Finally, Be ‘Done’ With Emails'}
                      </p>
                      <p className="margin-bottom-small">
                        {
                          'With the volume of email you receive on a daily basis, we understood the need for you to be done with certain messages. That’s why we created the “Done System,” a new and appealing way to file away the emails you don’t need to look at in your inbox anymore while keeping the option of referring back to them or retrieving again for use later on. It’s so simple and intuitive, all you do is tap on the avatar of the message for it to be marked as done. Information from a message you marked as done about shopping or travel is still available for reference at a glance within your smart folders. There is a noticeable and positive feeling of accomplishment you have when you mark a message as “Done.” Your inbox is less cluttered and you are empowered to keep moving on to what’s next.​​'
                        }
                      </p>
                      <p className="bold-text">
                        {'Suggested Unsubscribe & Inbox Clean Up'}
                      </p>
                      <p className="margin-bottom-small">
                        {
                          "​​Another couple of unique aspects of our service that help you maintain a clean inbox are our unique AI features like Suggested Unsubscribe and Inbox Clean Up. With these smart abilities, OnMail proactively offers to clean up mail you've lost interest in reading and remove clutter caused by old meeting invites still sitting in your mailbox. It’s easy to forget about the messages you might not need anymore but we are there to remind you when some emails haven’t been touched in a long time. This helps avoid email pile ups that can happen if you don’t have time to tidy up your inbox."
                        }
                      </p>
                      <p className="margin-bottom-small">
                        {
                          '​​Get a minimalistic interface design that highlights the best of email and gracefully weaves intelligence into your daily interactions. Savor the breathing room in your inbox and focus on what matters.'
                        }
                      </p>
                    </div>
                  </div>
                </OnMailPopUpFrame>
              }
            />
          )}
        </div>
        <Footer />
      </div>
    )
  }
}
