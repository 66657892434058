import React, { Component } from 'react'
import { Colors } from './../../utils/constants'
import { GetUrlVariable } from './../../utils/functions'
import { Link } from 'react-router-dom'
import MaterialIcon from 'material-icons-react'
import { Helmet } from 'react-helmet'
import Footer from './../../components/footer'

export default class OnmailOurStory extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    let error = false
    if (GetUrlVariable('error') == 'true') {
      error = true
    }
    return (
      <div className="full-width">
        <Helmet>
          <title>OnMail</title>
          <meta
            name="description"
            content="Get OnMail. Made with care by actual email experts-- the pioneers of one-tap Unsubscribe and creators of the award-winning #1 independent email app, Edison Mail.  "
          />
          <meta
            name="google-site-verification"
            content="yZ638yt0GZu1rqnAT2klj_g5IAsyi_33FQ_4MuBnptI"
          />
          <meta
            name="p:domain_verify"
            content="0703ddff50b36ba64a2edc3507c449ad"
          />
          <meta
            name="B-verify"
            content="3e7503ca0cb04368d4a783b926f0d84b95e74293"
          />
          <meta
            name="google-site-verification"
            content="JE63RlPVGqntnNKUZH5NpH3JO5SK2wPadGPkJ0FaIrY"
          />
          <script src="https://www.googleoptimize.com/optimize.js?id=OPT-MC8GN8X"></script>
          <link rel="icon" type="image/png" href="/images/onmail-favicon.png" />
          /* OpenGraph tags */
          <meta
            property="og:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            property="og:description"
            content="Experience email built for today."
          />
          <meta
            property="og:image"
            content="/images/onmail-social-share-img.png"
          />
          <meta property="og:url" content="https://www.onmail.com" />
          /* Twitter Card tags */
          <meta
            name="twitter:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            name="twitter:description"
            content="Experience email built for today."
          />
          <meta
            name="twitter:image"
            content="/images/onmail-social-share-img-team.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#2C2C2C" />
        </Helmet>
        <div
          className="large-padding background-image-fill"
          style={{
            height: '100vh',
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: 'url(/images/problem-1-background.jpg)',
          }}
        >
          {error ? (
            <div className="full-width">
              <h3 className="white">Unsubscribe Attempt Not Successful</h3>
              <p className="white">
                Please try again if you feel this is a mistake.
              </p>
            </div>
          ) : (
            <div className="full-width">
              <h3 className="white">Thank you</h3>
              <p className="white">You have successfully unsubscribed.</p>
              <p className="white">
                You will no longer receive product update emails from us.
              </p>
            </div>
          )}
        </div>
        <Footer />
      </div>
    )
  }
}
