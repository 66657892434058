import React, { Component } from 'react'
import {
  Colors,
  LatestDownloadableMacFile,
  LatestDownloadableAndroidLink,
  LatestDownloadableIosLink,
} from './../../utils/constants'
import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import TrackedLink from './../../components/trackedLink'
import MaterialIcon from 'material-icons-react'
import { Helmet } from 'react-helmet'
import ScrollFadeIn from './../../components/scrollFadeIn'
import ResponsiveImage from './../../components/responsiveImage'
import Footer from './../../components/footer'
import FullWidthImageText from './../../components/fullWidthImageText'
import ImageTitleText from './../../components/imageTitleText'
import { withController, Parallax } from 'react-scroll-parallax'
import './connect.scss'
import { TelegramShareButton } from 'react-share'

const Tabs = {
  IOS: 'ios-instructions',
  Android: 'android-instructions',
  Custom: 'custom-domain-instructions',
}

export default class OnmailConnect extends Component {
  constructor(props) {
    super(props)
    this.state = { activeTab: Tabs.IOS }
  }

  changeTab = (e, tab) => {
    e.preventDefault()
    this.setState({ activeTab: tab }, () => {
      const element = document.getElementById(tab)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    })
  }

  render() {
    const { activeTab } = this.state

    return (
      <div className="full-width onmail-connect-page">
        <Helmet>
          <title>
            Connect OnMail to Edison Mail | OnMail | A New Kind of Email by
            Edison Software
          </title>
          <meta
            name="description"
            content="OnMail by Edison Software. It's time to change email."
          />
          <meta
            name="google-site-verification"
            content="yZ638yt0GZu1rqnAT2klj_g5IAsyi_33FQ_4MuBnptI"
          />
          <meta
            name="p:domain_verify"
            content="0703ddff50b36ba64a2edc3507c449ad"
          />
          <meta
            name="B-verify"
            content="3e7503ca0cb04368d4a783b926f0d84b95e74293"
          />
          <meta
            name="google-site-verification"
            content="JE63RlPVGqntnNKUZH5NpH3JO5SK2wPadGPkJ0FaIrY"
          />
          <meta name="apple-itunes-app" content="app-id=922793622" />
          <meta name="google-play-app" content="app-id=com.easilydo.mail" />
          <meta property="og:title" content="OnMail by Edison Software" />
          <meta
            property="og:description"
            content="It's time to change email. Choose from thousands of custom domains included free from us to make an email address that sets you apart. "
          />
          <meta
            property="og:image"
            content="/images/onmail-social-share-img.png"
          />
          <meta property="og:url" content="https://www.onmail.com" />
          <meta name="twitter:title" content="OnMail by Edison Software" />
          <meta
            name="twitter:description"
            content="It's time to change email. Choose from thousands of custom domains included free from us to make an email address that sets you apart."
          />
          <meta
            name="twitter:image"
            content="/images/onmail-social-share-img-connect.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#2C2C2C" />
          <link rel="icon" type="image/png" href="/images/onmail-favicon.png" />
        </Helmet>

        <div
          className="full-width full-height"
          style={{ position: 'relative' }}
        >
          <div className="medium-top-margin mobile-top-padding centered-text">
            <div className="max-width-container">
              <div className="tiny-padding">
                <img width="120" src="/images/account-icon-edison.png" />
              </div>
              <div
                className="small-padding"
                style={{ maxWidth: '1000px', margin: '0 auto' }}
              >
                <h3 className="no-margin">
                  {'Connect An OnMail Account to Edison Mail'}
                </h3>
                <p className="small-top-margin no-bottom-padding medium-grey-color show-for-small">
                  {'I want instructions for:'}
                </p>
              </div>

              <div id="instruction-buttons" className="row tiny-top-margin">
                <div
                  className={`small-4 medium-4 ${
                    activeTab === Tabs.IOS ? 'active-button' : ''
                  }`}
                >
                  <a onClick={e => this.changeTab(e, Tabs.IOS)}>
                    <div className="button-container small-padding">
                      <img width="50" src="/images/icon-apple.png" />
                      <p
                        className="bold-text dark-grey-color"
                        style={{ padding: '16px 0px 4px 0px' }}
                      >
                        iOS
                      </p>
                      <p
                        className="subheading dark-grey-color hide-for-small"
                        style={{ padding: '4px 16px 8px 16px' }}
                      >
                        {'I want to attach my OnMail email to'}&nbsp;{'Edison'}
                        &nbsp;{'Mail on my'}&nbsp;
                        {'iPhone.'}
                      </p>
                      <div style={{ alignSelf: 'flex-end' }}>
                        <MaterialIcon
                          className="material-icons small-icon"
                          icon="keyboard_arrow_down"
                          color={Colors.primaryTextColor}
                        />
                      </div>
                    </div>
                  </a>
                </div>

                <div
                  className={`small-4 medium-4 ${
                    activeTab === Tabs.Android ? 'active-button' : ''
                  }`}
                >
                  <a onClick={e => this.changeTab(e, Tabs.Android)}>
                    <div className="button-container small-padding">
                      <MaterialIcon
                        className="material-icons"
                        icon="android"
                        color={Colors.primaryTextColor}
                      />
                      <p
                        className="bold-text dark-grey-color"
                        style={{ padding: '16px 0px 4px 0px' }}
                      >
                        Android
                      </p>
                      <p
                        className="subheading dark-grey-color hide-for-small"
                        style={{ padding: '4px 16px 8px 16px' }}
                      >
                        I want to attach my OnMail email to Edison Mail on my
                        Android device.
                      </p>
                      <div style={{ alignSelf: 'flex-end' }}>
                        <MaterialIcon
                          className="material-icons small-icon"
                          icon="keyboard_arrow_down"
                          color={Colors.primaryTextColor}
                        />
                      </div>
                    </div>
                  </a>
                </div>

                <div
                  className={`small-4 medium-4 ${
                    activeTab === Tabs.Custom ? 'active-button' : ''
                  }`}
                >
                  <a onClick={e => this.changeTab(e, Tabs.Custom)}>
                    <div className="button-container small-padding">
                      <MaterialIcon
                        className="material-icons"
                        icon="alternate_email"
                        color={Colors.primaryTextColor}
                      />
                      <p
                        className="bold-text dark-grey-color"
                        style={{ padding: '16px 0px 4px 0px' }}
                      >
                        Custom Domain
                      </p>
                      <p
                        className="subheading dark-grey-color hide-for-small"
                        style={{ padding: '4px 16px 8px 16px' }}
                      >
                        {
                          'I have a custom domain and I want to attach it to the Edison'
                        }
                        &nbsp;
                        {'Mail app on my phone.'}
                      </p>
                      <div style={{ alignSelf: 'flex-end' }}>
                        <MaterialIcon
                          className="material-icons small-icon"
                          icon="keyboard_arrow_down"
                          color={Colors.primaryTextColor}
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {activeTab === Tabs.IOS && (
          <div id="ios-instructions" className="">
            <div className="light-grey-background medium-bottom-margin">
              <div
                className="max-width-container large-padding centered-text"
                style={{
                  backgroundImage: "url('/images/icon-apple-bg.png')",
                  backgroundSize: '300px',
                  backgroundPosition: 'left center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <h5 className="uppercase bold">&nbsp;</h5>
              </div>
            </div>
            <div className="max-width-container small-padding center-for-small">
              <h6 className="uppercase bold tiny-padding">iOS Instructions</h6>
              <p className="medium-grey-color tiny-padding no-top-padding">
                {
                  'Follow these steps to attach an OnMail email address to Edison'
                }
                &nbsp;
                {'Mail on your'}&nbsp;{'iPhone:'}
              </p>
            </div>
            <FullWidthImageText
              title="Step 1"
              description="If you already have the Edison Mail app, open the side menu, and tap the blue plus icon inside the circle to add an account. If you just downloaded Edison Mail, you'll see the Add Account screen."
              parallaxImage1="/images/connect-ios-1.png"
              backgroundColor="#fff"
            />
            <FullWidthImageText
              title="Step 2"
              description="Enter your OnMail email and password to sign in."
              parallaxImage1="/images/connect-ios-2.png"
              backgroundColor="#fff"
              layout="right"
            />
            <FullWidthImageText
              title="Step 3"
              description="Welcome to your inbox! Enjoy your new OnMail account built to work with the Edison Mail app on mobile."
              parallaxImage1="/images/connect-ios-3.png"
              backgroundColor="#fff"
            />
          </div>
        )}

        {activeTab === Tabs.Android && (
          <div id="android-instructions" className="">
            <div className="light-grey-background medium-bottom-margin">
              <div
                className="max-width-container large-padding centered-text"
                style={{
                  backgroundImage: "url('/images/icon-android-bg.png')",
                  backgroundSize: '300px',
                  backgroundPosition: 'left center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <h5 className="uppercase bold">&nbsp;</h5>
              </div>
            </div>
            <div className="max-width-container small-padding center-for-small">
              <h6 className="uppercase bold tiny-padding">
                Android Instructions
              </h6>
              <p className="medium-grey-color tiny-padding no-top-padding">
                {
                  'Follow these steps to attach an OnMail email address to Edison'
                }
                &nbsp;
                {'Mail on your'}&nbsp;{'Android device:'}
              </p>
            </div>
            <FullWidthImageText
              title="Step 1"
              description="If you already have the Edison Mail app, open the side menu and tap the blue plus icon inside the circle to add an account. If you just downloaded Edison Mail, you'll see the Add Account screen."
              parallaxImage1="/images/connect-android-1.png"
              backgroundColor="#fff"
            />
            <FullWidthImageText
              title="Step 2"
              description="To add your account, choose Other."
              parallaxImage1="/images/connect-android-2.png"
              backgroundColor="#fff"
              layout="right"
            />
            <FullWidthImageText
              title="Step 3"
              description="Sign in with your OnMail email address and password."
              parallaxImage1="/images/connect-android-3.png"
              backgroundColor="#fff"
            />
            <FullWidthImageText
              title="Welcome to your inbox!"
              description="Enjoy your new OnMail account built to work with the Edison Mail app on mobile."
              parallaxImage1="/images/connect-android-4.png"
              backgroundColor="#fff"
              layout="right"
            />
          </div>
        )}

        {activeTab === Tabs.Custom && (
          <div id="custom-domain-instructions">
            <div className="light-grey-background medium-bottom-margin">
              <div
                className="max-width-container large-padding centered-text"
                style={{
                  backgroundImage: "url('/images/icon-custom-domain-bg.png')",
                  backgroundSize: '300px',
                  backgroundPosition: 'left center',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <h5 className="uppercase bold">&nbsp;</h5>
              </div>
            </div>
            <div className="max-width-container small-padding center-for-small">
              <h6 className="uppercase bold tiny-padding">
                Custom Domain Instructions
              </h6>
              <p className="medium-grey-color tiny-padding no-top-padding">
                {
                  'Follow these steps to attach an OnMail custom domain email address to Edison'
                }
                &nbsp;
                {'Mail on your'}&nbsp;{'device:'}
              </p>
            </div>
            <FullWidthImageText
              title="Step 1"
              description="Enter your OnMail custom domain email address."
              parallaxImage1="/images/connect-custom-domain-1.png"
              backgroundColor="#fff"
            />
            <FullWidthImageText
              title="Step 2"
              description="Enter your account password, and click Sign In."
              parallaxImage1="/images/connect-custom-domain-2.png"
              backgroundColor="#fff"
              layout="right"
            />
            <FullWidthImageText
              title="Step 3"
              description="Welcome to your inbox! Enjoy your OnMail custom domain account-built to work with the Edison Mail app on mobile. "
              parallaxImage1="/images/connect-ios-3.png"
              backgroundColor="#fff"
            />
          </div>
        )}

        <div className="section-padding border-top medium-top-margin">
          <div className="small-max-width-container">
            <div className="small-padding centered-text">
              <h3>Still having trouble?</h3>
              <h5>
                Enter the following in your Advanced Settings, and try again.
              </h5>
            </div>
            <div className="row middle tiny-padding">
              <div className="small-12 medium-6 large-6 card small-padding">
                <h6 className="medium-grey-color uppercase bold">
                  Incoming Mail Server (IMAP)
                </h6>
                <div
                  className="row"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h5 className="dark-grey-color">Hostname</h5>
                  <h5 className="primary-color">imap.onmail.com</h5>
                </div>
                <div
                  className="row"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h5 className="dark-grey-color">Port Number</h5>
                  <h5 className="primary-color">993</h5>
                </div>
                <h6 className="medium-grey-color uppercase bold medium-top-margin">
                  Outgoing Mail Server (SMTP)
                </h6>
                <div
                  className="row"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h5 className="dark-grey-color">Hostname</h5>
                  <h5 className="primary-color">smtp.onmail.com</h5>
                </div>
                <div
                  className="row"
                  style={{ justifyContent: 'space-between' }}
                >
                  <h5 className="dark-grey-color">Port Number</h5>
                  <h5 className="primary-color">465</h5>
                </div>
              </div>
              <div className="small-12 medium-6 large-6 small-padding centered-text hide-for-small">
                <img
                  className="full-width"
                  src="/images/connect-advanced-settings.png"
                />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}
