import React, { Component } from 'react'
import {
  Colors,
  OnmailColors,
  LatestDownloadableOnmailIosLink,
} from './../../utils/constants'
import { Link } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import TrackedLink from './../../components/trackedLink'
import MaterialIcon from 'material-icons-react'
import { Helmet } from 'react-helmet'
import ScrollFadeIn from './../../components/scrollFadeIn'
import Footer from './../../components/footer'
import OnMailPopUpFrame from './../../components/onMailPopUpFrame'
import PortalModal from './../../components/portalModal'
import OnmailHowToStep from './../../components/onmailHowToStep'
import './getStarted.scss'

export default class OnmailGetStarted extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  showModal = type => {
    this.hideModal()
    this.setState({
      [type]: true,
    })
  }
  hideModal = () => {
    this.setState({
      getStarted1Expanded: false,
      getStarted2Expanded: false,
      getStarted3Expanded: false,
    })
  }

  render() {
    let color = Colors.onmailPrimaryColor

    return (
      <div className="full-width onmail-get-started-page">
        <Helmet>
          <title>Getting Started — Just a Few Simple Steps</title>
          <meta
            name="description"
            content="Set up your brand new OnMail account in 3 minutes or less. "
          />
          <meta
            name="google-site-verification"
            content="yZ638yt0GZu1rqnAT2klj_g5IAsyi_33FQ_4MuBnptI"
          />
          <meta
            name="p:domain_verify"
            content="0703ddff50b36ba64a2edc3507c449ad"
          />
          <meta
            name="B-verify"
            content="3e7503ca0cb04368d4a783b926f0d84b95e74293"
          />
          <meta
            name="google-site-verification"
            content="JE63RlPVGqntnNKUZH5NpH3JO5SK2wPadGPkJ0FaIrY"
          />
          <meta name="theme-color" content="#2C2C2C" />
          <link rel="icon" type="image/png" href="/images/onmail-favicon.png" />
          /* OpenGraph tags */
          <meta
            property="og:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            property="og:description"
            content="Experience email built for today."
          />
          <meta
            property="og:image"
            content="/images/onmail-social-share-img.png"
          />
          <meta property="og:url" content="https://www.onmail.com" />
          /* Twitter Card tags */
          <meta
            name="twitter:title"
            content="OnMail – Ultra-Fast, Dead Simple Email by Edison"
          />
          <meta
            name="twitter:description"
            content="Experience email built for today."
          />
          <meta
            name="twitter:image"
            content="/images/onmail-social-share-img-getstarted.png"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="theme-color" content="#2C2C2C" />
        </Helmet>

        <div className="section-padding centered-text max-width-container">
          <h1 className="padding-top-large padding-bottom-medium padding-left-small padding-right-small">
            {'How do I get started with OnMail?'}
          </h1>
          <div
            className="row middle"
            style={{ alignItems: 'stretch', justifyContent: 'center' }}
          >
            <div className="small-12 medium-4 large-3 padding-tiny">
              <a onClick={() => this.showModal('getStarted1Expanded')}>
                <div className="card full-height padding-small">
                  <div className="padding-small">
                    <img
                      className="full-width"
                      src="/images/get-started-new.png"
                      alt=""
                    />
                  </div>
                  <div className="row">
                    <h4 className="onmail-black">
                      {'Create a new OnMail account and email address.'}
                    </h4>
                    <div
                      className="small-12 row middle margin-top-tiny"
                      style={{ justifyContent: 'center' }}
                    >
                      <div className="padding-bottom-small">
                        <MaterialIcon
                          className="material-icons"
                          icon="arrow_forward"
                          color={OnmailColors.onmailBlack}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="small-12 medium-4 large-3 padding-tiny">
              <a onClick={() => this.showModal('getStarted2Expanded')}>
                <div className="card full-height padding-small">
                  <div className="padding-small">
                    <img
                      className="full-width"
                      src="/images/get-started-custom-domain.png"
                      alt=""
                    />
                  </div>
                  <div className="row">
                    <h4 className="onmail-black">
                      {'Create a new account with your own custom'}&nbsp;
                      {'domain.'}
                    </h4>
                    <div
                      className="small-12 row middle margin-top-tiny"
                      style={{ justifyContent: 'center' }}
                    >
                      <div className="padding-bottom-small">
                        <MaterialIcon
                          className="material-icons"
                          icon="arrow_forward"
                          color={OnmailColors.onmailBlack}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="small-12 medium-4 large-3 padding-tiny">
              <a onClick={() => this.showModal('getStarted3Expanded')}>
                <div className="card full-height padding-small">
                  <div className="padding-small">
                    <img
                      className="full-width"
                      src="/images/get-started-import.png"
                      alt=""
                    />
                  </div>
                  <div className="row">
                    <h4 className="onmail-black">
                      {'Bring over your existing accounts. Modernize'}&nbsp;
                      {'them.'}
                    </h4>
                    <div
                      className="small-12 row middle margin-top-tiny"
                      style={{ justifyContent: 'center' }}
                    >
                      <div className="padding-bottom-small">
                        <MaterialIcon
                          className="material-icons"
                          icon="arrow_forward"
                          color={OnmailColors.onmailBlack}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>

        {this.state.getStarted1Expanded && (
          <PortalModal
            modalHidden={this.hideModal}
            modalActive={this.state.getStarted1Expanded}
            theme="circled"
            fullScreen
            content={
              <OnMailPopUpFrame
                title={'Create an OnMail Address'}
                description={''}
                image={'/images/getstarted-1-background.jpg'}
              >
                <div className="">
                  <div className="max-width-container padding-small">
                    <OnmailHowToStep
                      subtitle="Step 1"
                      title="Click Sign Up"
                      description="Click the blue button that says Sign Up, then choose the plan that best fits your needs. Note: Invite-only Free plans have long wait periods due to high demand."
                      image="/images/get-started-create-new-1.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 2"
                      title="Select Your Email Address"
                      description="Enter your info to find an email address that suits you. Note: Every address can only be used once, so shorter names require a Personal or Professional plan."
                      image="/images/get-started-create-new-2.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 3"
                      title="View Your New Inbox"
                      description="Explore the unique features of OnMail and send your first message."
                      image="/images/get-started-create-new-3.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 4"
                      title="Set Up OnMail Mobile"
                      description="Download OnMail’s iOS app at the App Store for a complete experience. Android is coming soon."
                      image="/images/get-started-download.png"
                    />
                    <div className="row full-width">
                      <div className="small-12 medium-6 large-6"></div>
                      <div className="small-12 medium-6 large-6 padding-tiny">
                        <TrackedLink link={LatestDownloadableOnmailIosLink}>
                          <img
                            style={{ height: '50px' }}
                            src="/images/app-store-badge.svg"
                            alt=" "
                          />
                        </TrackedLink>
                      </div>
                    </div>
                  </div>
                </div>
              </OnMailPopUpFrame>
            }
          />
        )}

        {this.state.getStarted2Expanded && (
          <PortalModal
            modalHidden={this.hideModal}
            modalActive={this.state.getStarted2Expanded}
            theme="circled"
            fullScreen
            content={
              <OnMailPopUpFrame
                title={[
                  'Create a Custom Domain',
                  <span>&nbsp;</span>,
                  'Address',
                ]}
                description={''}
                image={'/images/getstarted-2-background.jpg'}
              >
                <div className="">
                  <div className="max-width-container padding-small">
                    <OnmailHowToStep
                      subtitle="Step 1"
                      title="Click Sign Up"
                      description="Click the blue button that says Sign Up, then choose either the Personal or Professional plan that best fits your needs."
                      image="/images/get-started-custom-domain-1.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 2"
                      title="Choose an Email Address"
                      description="Create an @onmail.com account and address, then enter your payment info. This is required before you can choose or integrate an additional custom domain email address."
                      image="/images/get-started-custom-domain-2.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 3"
                      title="Go to Domain Settings"
                      description="Tap your avatar circle in the top right of your screen and select Settings. Then click ‘My Domain’."
                      image="/images/get-started-custom-domain-3.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 4"
                      title="Find Your Domain"
                      description="Click on ‘Get your own domain,’ enter a name you like and select continue to claim. Or select ‘Connect my existing domain,’ if applicable."
                      image="/images/get-started-custom-domain-4.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 5"
                      title="Set Up OnMail Mobile"
                      description="Download OnMail’s iOS app at the App Store for a complete experience. Android is coming soon."
                      image="/images/get-started-download.png"
                    />
                    <div className="row full-width">
                      <div className="small-12 medium-6 large-6"></div>
                      <div className="small-12 medium-6 large-6 padding-tiny">
                        <TrackedLink link={LatestDownloadableOnmailIosLink}>
                          <img
                            style={{ height: '50px' }}
                            src="/images/app-store-badge.svg"
                            alt=" "
                          />
                        </TrackedLink>
                      </div>
                    </div>
                  </div>
                </div>
              </OnMailPopUpFrame>
            }
          />
        )}

        {this.state.getStarted3Expanded && (
          <PortalModal
            modalHidden={this.hideModal}
            modalActive={this.state.getStarted3Expanded}
            theme="circled"
            fullScreen
            content={
              <OnMailPopUpFrame
                title={[
                  'Modernize Your Existing',
                  <span>&nbsp;</span>,
                  'Accounts',
                ]}
                description={''}
                image={'/images/getstarted-3-background.jpg'}
              >
                <div className="">
                  <div className="max-width-container padding-small">
                    <div
                      className="background-onmail-extra-light-grey padding-small margin-bottom-medium"
                      style={{ borderRadius: '8px' }}
                    >
                      <p>
                        <span className="bold-text onmail-primary">
                          {'Note:'}
                        </span>
                        {
                          ' OnMail’s new Import Account System to modernize your existing email accounts from other providers is available for Personal and Professional (paid) plans only.'
                        }
                      </p>
                    </div>
                    <OnmailHowToStep
                      subtitle="Step 1"
                      title="Sign Into Your Account"
                      description="Go to the OnMail login page to sign in to your existing account. Note: If you don’t have an account yet, you need to Create an OnMail Address."
                      image="/images/get-started-import-1.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 2"
                      title="Go to Acount Settings"
                      description="Tap your avatar circle in the top right of your screen and select Settings. Then click ‘Account’."
                      image="/images/get-started-import-3.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 3"
                      title="Import Your Accounts"
                      description="Scroll down and click ‘Import a new account’. Choose the provider to import and follow the instructions to complete the process."
                      image="/images/get-started-import-4.png"
                    />
                    <OnmailHowToStep
                      subtitle="Step 4"
                      title="Set Up OnMail Mobile"
                      description="Download OnMail’s iOS app at the App Store for a complete experience. Android is coming soon."
                      image="/images/get-started-download.png"
                    />
                    <div className="row full-width">
                      <div className="small-12 medium-6 large-6"></div>
                      <div className="small-12 medium-6 large-6 padding-tiny">
                        <TrackedLink link={LatestDownloadableOnmailIosLink}>
                          <img
                            style={{ height: '50px' }}
                            src="/images/app-store-badge.svg"
                            alt=" "
                          />
                        </TrackedLink>
                      </div>
                    </div>
                  </div>
                </div>
              </OnMailPopUpFrame>
            }
          />
        )}

        <Footer />
      </div>
    )
  }
}
