import Parse from 'parse'

export const GetUrlVariable = variable => {
  var query = window.location.search.substring(1)
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}

export const GetSiteText = slug => {
  let text = ''
  if (window.siteText[slug]) {
    text = window.siteText[slug].attributes.text
  }
  if (text && text.indexOf('<a') != -1) {
    text = '<span>' + text + '</span>'
  }
  return text
}

export const GetBlogPostsArticles = async category => {
  let currentTime = new Date().getTime()
  const Press = Parse.Object.extend('BlogPosts')
  const press = new Parse.Query(Press)
  press.descending('date')
  press.limit(1000)
  if (category) {
    press.equalTo('category', category)
  }
  const results = await press.find()
  results.filter(
    article => new Date(article.attributes.date).getTime() > currentTime
  )
  return results
}

export const GetPressReleases = async () => {
  let currentTime = new Date().getTime()
  const Press = Parse.Object.extend('News')
  const press = new Parse.Query(Press)
  press.descending('date')
  press.containedIn('products', ['pressReleases'])
  press.limit(1000)
  let results = await press.find()
  results = results.filter(
    article => new Date(article.attributes.date).getTime() < currentTime
  )
  return results
}

export const GetNewsArticles = async products => {
  let currentTime = new Date().getTime()
  const Articles = Parse.Object.extend('News')
  const articles = new Parse.Query(Articles)
  articles.descending('date')
  articles.limit(1000)
  if (products) {
    articles.containedIn('products', products)
  }
  let results = await articles.find()
  results = results.filter(
    article => new Date(article.attributes.date).getTime() < currentTime
  )
  return results
}

export const GetAllNewsArticles = async product => {
  const Articles = Parse.Object.extend('News')
  const articles = new Parse.Query(Articles)
  articles.descending('date')
  articles.limit(1000)
  const results = await articles.find()
  return results
}

export const GetAllBlogPosts = async () => {
  const BlogPosts = Parse.Object.extend('BlogPosts')
  const posts = new Parse.Query(BlogPosts)
  posts.descending('date')
  posts.equalTo('deleted', undefined)
  posts.limit(1000)
  let results = await posts.find()
  for (var i = 0; i < results.length; i++) {
    if (results[i].attributes.date == undefined) {
      results.unshift(results[i])
      console.log(results)
      results = results.splice(i, 1)
    }
  }
  return results
}

export const FormatDate = string => {
  if (string) {
    let date = new Date(string)
    let monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    if (date) {
      let day = date.getDate()
      let monthIndex = date.getMonth()
      let year = date.getFullYear()

      return monthNames[monthIndex] + ' ' + day + ', ' + year
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export const TruncateDescription = description => {
  if (description.length > 80) {
    description = description.slice(0, 80) + '...'
  }
  return description
}

export const TruncateTitle = title => {
  if (title.length > 70) {
    title = title.slice(0, 70) + '...'
  }
  return title
}
export const AddToClipBoard = value => {
  const el = document.createElement('textarea')
  el.value = value
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  alert('Copied: ' + value)
}

export const GetDomainFromUrl = url => {
  var hostName = getHostName(url)
  var domain = hostName

  if (hostName != null) {
    var parts = hostName.split('.').reverse()
    if (parts != null && parts.length > 1) {
      domain = parts[1] + '.' + parts[0]
    }
    if (hostName.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
      domain = parts[2] + '.' + domain
    }
  }
  return domain

  function getHostName(url) {
    var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
    if (
      match != null &&
      match.length > 2 &&
      typeof match[2] === 'string' &&
      match[2].length > 0
    ) {
      return match[2]
    } else {
      return null
    }
  }
}
