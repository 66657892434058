import React, { Component } from 'react'
import ReactPlayer from 'react-player'

export default class ProductVideo extends Component {
  render() {
    return (
      <div className="section-padding full-width">
        <div className="max-width-container">
          <ReactPlayer
            controls
            className="embed-container"
            url="https://vimeo.com/532395734/a6c110bf88"
            width="100%"
          />
        </div>
      </div>
    )
  }
}
