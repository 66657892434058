import React, { Component } from 'react'
import { Colors } from './../../utils/constants'
import './onmailFramedFullWidthImageText.scss'
import { Link } from 'react-router-dom'
import TrackedLink from './../../components/trackedLink'
import MaterialIcon from 'material-icons-react'

export default class OnmailFramedFullWidthImageText extends Component {
  constructor(props) {
    super(props)
    this.containerRef = React.createRef()
    this.state = {}
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.checkInView)
  }
  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.checkInView)
  }
  checkInView = e => {
    //console.log(document.body.scrollTop)
    const elem = this.containerRef.current
    let bounding = elem.getBoundingClientRect()
    console.log(bounding)
    if (
      bounding.top < window.innerHeight * 0.25 &&
      bounding.top > -1 * window.innerHeight * 0.25
    ) {
      if (!this.state.active) {
        this.setState({
          active: true,
        })
      }
    } else {
      if (this.state.active) {
        this.setState({
          active: false,
        })
      }
    }
  }
  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick()
    } else {
      window.location.assign(this.props.link)
    }
  }
  render() {
    let title = this.props.title
    let description = this.props.description
    let linkTitle = this.props.linkTitle
    let link = this.props.link
    let backgroundImage = this.props.backgroundImage
    let color = Colors.white
    if (this.props.theme && this.props.theme == 'dark') {
      color = Colors.black
    }
    let activeClass = ''
    if (this.state.active) {
      activeClass = 'active'
    }
    return (
      <div
        className={'onmail-framed-full-width-image-text ' + activeClass}
        ref={this.containerRef}
      >
        <div
          className="row full-width full-height onmail-framed-full-width-image-text-overlay large-padding"
          style={{
            alignItems: 'center',
            alignContent: 'center',
            position: 'relative',
          }}
        >
          <div className="small-12 medium-8 large-7">
            {title && <h2 style={{ color: color }}>{title}</h2>}
            {description && (
              <h4
                className="margin-top-tiny regular-text"
                style={{ color: color }}
              >
                {description}
              </h4>
            )}
            {link && (
              <a onClick={this.onClick}>
                <div className="row middle margin-top-tiny">
                  {linkTitle && <p className="white bold-text">{linkTitle}</p>}
                  <div style={{ position: 'relative', left: '10px' }}>
                    <MaterialIcon
                      className="material-icons animate-right small-icon"
                      icon="arrow_forward"
                      color={color}
                    />
                  </div>
                </div>
              </a>
            )}
          </div>
        </div>
        <div
          className="onmail-framed-full-width-image-text-background"
          style={{
            backgroundImage: 'url(' + backgroundImage + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        ></div>
      </div>
    )
  }
}
