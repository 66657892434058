import './styles/app.scss'
import './styles/onmail.scss'
import React from 'react'
import { render } from 'react-dom'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './app'
import { ParallaxProvider } from 'react-scroll-parallax'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const renderApp = Component =>
  render(
    <Router history={history}>
      <ParallaxProvider>
        <Component />
      </ParallaxProvider>
    </Router>,
    document.getElementById('root')
  )

renderApp(App)
