import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { CanShareDataCheck } from './../../utils/analytics'

export default class TrackedLink extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  trackLink = async () => {
    let dataCheck = await CanShareDataCheck()
    let link = this.props.link
    if (dataCheck) {
      ReactGA.event({
        category: 'outbound',
        action: 'click',
        label: link,
      })
    }
    window.location.assign(link)
  }

  render() {
    return (
      <a {...this.props} onClick={this.trackLink}>
        {this.props.children}
      </a>
    )
  }
}
